<template>
	<div class="mypage-list">
		<!--0. 회원 정보-->
		<div class="mypage__userinfo">
			<span
				><strong>{{ $store.state.userid }}</strong
				>님</span
			><span>반갑습니다.</span>
			<span class="mypage__useremail">{{ useremail }}</span>
			<button @click="toMypageMain" class="btn--sm btn--border-gray4">
				마이페이지 홈
			</button>
		</div>
		<aside>
			<!--1. 이용 중인 서비스-->
			<ul>
				<li class="mypage__text--bold">
					이용 중인 서비스
					<ul>
						<li @click="toChangeList1" :class="{ listActive: isList1 }">
							구매 내역
						</li>
						<li @click="toChangeList2" :class="{ listActive: isList2 }">
							부가 서비스
						</li>
					</ul>
				</li>
			</ul>
			<!--2. 결제 서비스-->
			<ul>
				<li class="mypage__text--bold">
					결제 서비스
					<ul>
						<li @click="toChangeList3" :class="{ listActive: isList3 }">
							결제 내역
						</li>
						<li @click="toChangeList4" :class="{ listActive: isList4 }">
							환불 내역
						</li>
						<li @click="toChangeList5" :class="{ listActive: isList5 }">
							영수증
						</li>
					</ul>
				</li>
			</ul>
			<!--3. 회원 정보 관리-->
			<ul>
				<li class="mypage__text--bold">
					회원 정보 관리
					<ul>
						<!--<li @click="toChangeList6" :class="{ listActive: isList6 }">
							기업회원 전환 신청
						</li>-->
						<li @click="toChangeList6" :class="{ listActive: isList6 }">
							회원 정보 수정
						</li>
						<li @click="toChangeList7" :class="{ listActive: isList7 }">
							회원 탈퇴
						</li>
					</ul>
				</li>
			</ul>
		</aside>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isList1: false,
			isList2: false,
			isList3: false,
			isList4: false,
			isList5: false,
			isList6: false,
			isList7: false,
			username: '(주) 포체인스',
			useremail: 'fourchainsfcts@naver.com',
		};
	},
	methods: {
		toMypageMain() {
			this.$router.push('/mypage').catch(() => {});
			this.isList1 = false;
			this.isList2 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
		},
		toChangeList1() {
			this.isList1 = true;
			this.isList2 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/warning').catch(() => {});
		},
		toChangeList2() {
			this.isList2 = true;
			this.isList1 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/warning').catch(() => {});
		},
		toChangeList3() {
			this.isList3 = true;
			this.isList2 = false;
			this.isList1 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/warning').catch(() => {});
		},
		toChangeList4() {
			this.isList4 = true;
			this.isList2 = false;
			this.isList3 = false;
			this.isList1 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/warning').catch(() => {});
		},
		toChangeList5() {
			this.isList5 = true;
			this.isList2 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList1 = false;
			this.isList6 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/warning').catch(() => {});
		},
		toChangeList6() {
			this.isList6 = true;
			this.isList2 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList1 = false;
			this.isList7 = false;
			this.isList8 = false;
			this.$router.push('/mypage/changeinfo').catch(() => {});
		},
		toChangeList7() {
			this.isList7 = true;
			this.isList2 = false;
			this.isList3 = false;
			this.isList4 = false;
			this.isList5 = false;
			this.isList6 = false;
			this.isList1 = false;
			this.isList8 = false;
			this.$router.push('/mypage/withdrawal').catch(() => {});
		},
	},
};
</script>

<style></style>
