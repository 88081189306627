<template>
	<div>
		<main-header></main-header>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<div class="container--pa-160 mypage--bg">
			<mypage-list></mypage-list>
			<router-view @changeStep="changeStep" :listNum="listNum"></router-view>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
import MypageList from '../components/Mypage/MypageList.vue';
import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
export default {
	components: {
		Footer,
		MainHeader,
		MypageList,
		BreadCrumbs,
	},
	data() {
		return {
			menuKr: '마이 페이지',
			menuEn: 'My Page',
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '마이 페이지', link: '/mypage' },
			],
			listNum: '',
		};
	},
	methods: {
		changeStep(listNum) {
			this.listNum = listNum;
		},
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
